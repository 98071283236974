import { useWindowDimensions } from 'react-native';
import { safetyLevels, riskLevels } from '../constants';

export const isSmallScreen = () => {
    const isSmallScreen = useWindowDimensions().width < 768;
    return isSmallScreen;
}

export const isExtraBigScreen = () => {
    const isExtraBigScreen = useWindowDimensions().width > 1440;
    return isExtraBigScreen;
}

export const isMiddleScreen = () => {
    const width = useWindowDimensions().width;
    const isMiddleScreen = 768 <= width && width < 1030;
    return isMiddleScreen;
}

const isChrome = navigator.userAgent.includes("Chrome");
export { isChrome };

export const getSafetyLevelColor = (safetyLevel) => {
    const color = {
        [safetyLevels.UNKNOWN]: '#F5A623',
        [safetyLevels.FULLY_SAFE]: '#3E78C7',
        [safetyLevels.NOT_SAFE]: '#CB3E5A',
        [safetyLevels.MILDLY_SAFE]: '#DD8206',
    };
    return color[safetyLevel];
}

export const setTabNameAndIcon = () => {
    document.title = "Security Checkup";
    const link = document.createElement('link');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.rel = 'apple-touch-icon';
    link.href = require('../public/Logo.png')
    document.head.appendChild(link);
}

export const safefyLevelCalculator = ({ resultFound, esRiskLevel }) => {
    const dnsLevel = resultFound === true ? 'unsafe' : 'safe';
    const combinedLevel = [dnsLevel, esRiskLevel];

    if (combinedLevel.includes('unsafe') || combinedLevel.includes(riskLevels.HIGH)) {
        return safetyLevels.NOT_SAFE;
    }
    if (combinedLevel.includes(riskLevels.MEDIUM)) {
        return safetyLevels.MILDLY_SAFE;
    }
    if (combinedLevel.includes(riskLevels.LOW) || combinedLevel.includes('safe')) {
        return safetyLevels.FULLY_SAFE;
    }
    return safetyLevels.NOT_SAFE;

}

export function getTitleList() {
    const title = ['risk', 'port', 'service', 'description'];
    return title;
}

export function isProduction() {
    if (document.location.hostname.indexOf("surancecheck.me") != -1) {
        return true;
    } else {
        return false;
    }
}

export function orderKey(obj, keyOrder) {
    keyOrder.forEach((k) => {
        const v = obj[k]
        delete obj[k]
        obj[k] = v
    })
    return keyOrder;
}