const he = {
  scanId: "קוד סריקה: {shortScanId}",
  beingScanned: "הראוטר והרשת הביתית שלכם נסרקים כעת, נא המתינו…",
  homeNetworkSecurity: "סריקת הרשת הביתית לגילוי פרצות",
  ourInnovativeTool: "הכלי החדשני שלנו יאבחן האם בוצעו שינויים לא מורשים בראוטר שלכם ובהגדרותיו. כמו כן, הוא יבדוק האם המכשירים הביתיים שלכם שמחוברים לרשת, בסיכון לפריצה.",
  quicklyAndEasily: "בדקו את רמת האבטחה של הראוטר ורשת ה-Wi-Fi בביתכם בקלות ובמהירות",
  conntectedToWifi: "חשוב לוודא שאת/ה מחובר/ת לרשת ה-Wi-Fi לפני הרצת הסריקה",
  startTesting: "התחל בדיקה",
  congratulations: "כל הכבוד!",
  homeNetworkSafeAtTheMoment: "הרשת הביתית שלכם נמצאה בטוחה נכון לעכשיו. אנו ממליצים לחזור ולבצע את הסריקה מדי פעם כדי לאבחן פגיעויות.",
  mediumSecurityRisk: "הרשת הביתית שלכם אובחנה בסיכון נמוך עד בינוני לפריצה. אנא צרו קשר עם שירות הלקוחות שלנו: <b>support@surance.io</b> והעתיקו את קוד הסריקה לעזרה נוספת.",
  routerSecurityAlert: "התראת אבטחה של הראוטר",
  highRisksFound: "נמצאו סיכונים ברמה גבוהה",
  noRisksFound: "לא נמצאו סיכונים",
  lowRisksFound: "סיכונים ברמה נמוכה",
  highSecurityRisk: "הרשת הביתית שלכם אובחנה בסיכון גבוה לפריצה. אנא צרו קשר עם שירות הלקוחות שלנו: <b>support@surance.io</b> והעתיקו את קוד הסריקה לעזרה נוספת.",
  reachcustomerSupport: "כדי ללמוד כיצד לטפל בבעיה זו, צור קשר עם תמיכת הלקוחות לקבלת סיוע נוסף.",
  contactUs: "צור קשר",
  allRightsRegistered: "© Surance.io. כל הזכויות שמורות.",
  provider: "ספק:",
  location: "מיקום:",
  youHaveAlreadyScanned: "כבר ביצעת סריקה לרשת שלך.",
  noNetwork: "אין רשת",
  noNetworkMessage: "אנא בדוק את חיבור האינטרנט שלך ונסה שוב, אם הבעיה נמשכת פנה לתמיכה.",
  tryAgain: "נסה שוב",
  homeNetworkVulnerabilities: "חולשות ברשת הביתית",
  scanFailed: "לצערנו, לא הצלחנו לסרוק את הרשת הביתית שלך",
  rescan: "לסריקה חוזרת",
  safetyLevel: 'רמת אבטחה',
  riskLevel: "רמת אבטחה",
  high: "גבוהה",
  medium: "בינונית",
  low: "נמוכה",
  none: "גבוהה",
  includesDataFrom: "כולל מידע מ-",
  moreDetails: "למידע נוסף",
  hideDetails: "הסתר מידע",
}
module.exports = he;
