import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { useTheme, TextType, Text } from "@suranceadmin/surance-ui-components";
import { isSmallScreen, getTitleList, orderKey, isExtraBigScreen } from '../utils';
import { ItemDivider } from './ItemDivider';
import { RenderItemSmall, RenderItem } from './ESItem';
import { useIntl } from 'react-intl';

const ESContainer = ({ showESresult }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme);
    const intl = useIntl();

    const [bigScreenArray, setBigScreenArray] = useState([]);
    const [smallScreenArray, setSmallScreenArray] = useState([]);
    const { portsInfo } = showESresult;
    const titleList = getTitleList();

    useEffect(() => {
        for (let i = 0; i < titleList.length; i++) {
            let tempArray = [];
            portsInfo.forEach(element => {
                tempArray.push(element[titleList[i]]);
            });
            const tempObj = {
                title: titleList[i],
                data: tempArray
            }
            setBigScreenArray(prevBigScreenArray => ([...prevBigScreenArray, tempObj]));
        }
    }, []);

    useEffect(() => {
        let tempArray = [];
        const tempObj = {};
        titleList.forEach(element => {
            tempObj[element] = null;
        });
        const portsInfoBeforeChanges = portsInfo;
        portsInfoBeforeChanges.forEach(element => {
            delete element["isOpenHttpPort"];
            orderKey(element, Object.keys(tempObj));
            tempArray.push(element);

        });
        setSmallScreenArray([...tempArray]);
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.cont}>
                <Text type={TextType.SubtitleBold} style={styles.title}>
                    {intl.formatMessage({ id: 'homeNetworkVulnerabilities' })}
                </Text>
                <ESResult array={bigScreenArray} portsInfo={smallScreenArray} />
            </View>

        </View>
    );
};

const ESResult = ({ array, portsInfo }) => {
    const theme = useTheme();
    const isSmallDevice = isSmallScreen();
    const { styles } = useStyle(theme);
    return (
        <View style={[styles.esContainer, { writingDirection: theme.isRTL && 'rtl' }]}>
            {isSmallDevice ?
                <FlatList
                    key={'_'}
                    style={{ flex: 1 }}
                    data={portsInfo}
                    renderItem={({ item, index }) =>
                        <RenderItemSmall item={item} index={index} />}
                    keyExtractor={(item, index) => item + index}
                    ItemSeparatorComponent={ItemDivider}
                    contentContainerStyle={{ paddingBottom: 40 }}
                />
                :
                <FlatList
                    key={'#'}
                    contentContainerStyle={{ paddingBottom: 20 }}
                    data={array}
                    columnWrapperStyle={isSmallDevice ? null : {
                        flex: 1,
                        justifyContent: "space-around",
                    }}
                    numColumns={isSmallDevice ? null : 4}
                    renderItem={({ item, index }) =>
                        <RenderItem item={item} index={index} />}
                    keyExtractor={(item, index) => item + index}
                />
            }
        </View>
    );
};

const useStyle = (theme) => {
    const isSmallDevice = isSmallScreen();
    const isExtraBigDevice = isExtraBigScreen();

    const styles = StyleSheet.create({
        container: {
            flex: isSmallDevice ? null : 1,
            minHeight: 150,
            backgroundColor: theme.colors.background.white,
            marginLeft: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            marginRight: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            borderBottomLeftRadius: isSmallDevice ? 0 : 8,
            borderBottomRightRadius: isSmallDevice ? 0 : 8,
            marginBottom: isSmallDevice ? 0 : 64
        },
        cont: {
            flex: 1,
            paddingLeft: isSmallDevice ? 24 : 60,
            paddingRight: isSmallDevice ? 24 : 60,
        },
        esContainer: {
            flex: 1,
        },
        item: {
            backgroundColor: '#f9c2ff',
            flexdirection: 'column',
        },
        header: {
            fontSize: 32,
            backgroundColor: '#fff',
        },
        leftContainerBorderResult: {
            borderTopWidth: 1,
            borderColor: 'rgb(192, 192, 192)',
            marginTop: -6,
            width: '100%',
        },
        title: {
            color: theme.colors.text.link,
            marginBottom: 16,
            marginTop: isSmallDevice ? 24 : 0,
        },
    });

    return { styles };
};
export default ESContainer;