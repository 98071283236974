import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme, Text, TextType } from "@suranceadmin/surance-ui-components";
import { isSmallScreen, getSafetyLevelColor, isMiddleScreen } from '../../../utils';
import { scanStatuses, riskLevels } from '../../../../constants';
import { getName } from 'country-list';
import { useIntl } from 'react-intl';
import { RiskLevelDetails } from './RiskLevelDetails';
import { DnsDetails } from './DnsDetails';

const getMiddleScreenText = (intl, provider, riskLevelText, showRiskLevel, riskLevel) => {
  provider = `${provider.substring(0, 24)}...`;
  riskLevelText = `${showRiskLevel ? intl.formatMessage({ id: `${riskLevel ? riskLevel : riskLevels.NONE}` }) : intl.formatMessage({ id: "none" })}`;
  const text = {
    provider,
    riskLevelText
  };
  return text;
}

const getCountryName = (name: string) => {
  let countryName = name;
  if (name) {
    countryName = getName(name);
  }
  return countryName || name;
}

const getCountryText = (showDNSResult) => {
  const country = ["", undefined].includes(showDNSResult.country) ? '' : `${getCountryName(showDNSResult.country)},`;
  return country;
}

const getLocationText = (intl, showDNSResult) => {
  const country = getCountryText(showDNSResult);
  const location = `${intl.formatMessage({ id: "location" })} ${country} ${showDNSResult.city}`;
  return location;
}

const getESSafetyByRiskLevel = (riskLevel) => {
  const intl = useIntl();
  const safetyMapping = {
    [riskLevels.LOW]: intl.formatMessage({ id: "high" }),
    [riskLevels.MEDIUM]: intl.formatMessage({ id: "medium" }),
    [riskLevels.HIGH]: intl.formatMessage({ id: "low" }),
    [riskLevels.NONE]: intl.formatMessage({ id: "high" }),
  }
  return safetyMapping[riskLevel];
}


const LeftContainer = ({ scanStatus, openESView, handleOpenESView, showDNSResult, safetyLevel, riskLevel }) => {
  const [vulnerabilitstText, setVulnerabilitstText] = useState('');
  const showRiskLevel = riskLevel !== riskLevels.LOW;
  const esSafetyLevel = getESSafetyByRiskLevel(riskLevel);
  const theme = useTheme();
  const intl = useIntl();
  const middleScreen = isMiddleScreen();
  const { styles } = useStyle(theme, openESView);
  const riskLevelColor = getSafetyLevelColor(safetyLevel);
  let riskLevelText = `${intl.formatMessage({ id: "safetyLevel" })}: ${showRiskLevel ? intl.formatMessage({ id: `${riskLevel ? esSafetyLevel : riskLevels.HIGH}` }) : intl.formatMessage({ id: "high" })}`;
  const locationText = getLocationText(intl, showDNSResult);
  let provider = `${intl.formatMessage({ id: "provider" })} ${showDNSResult.org}`;
  if (middleScreen) {
    const text = getMiddleScreenText(intl, provider, riskLevelText, showRiskLevel, riskLevel);
    provider = text.provider;
    riskLevelText = text.riskLevelText;
  }
  const showLocationAndProvider = showDNSResult.country !== undefined && showDNSResult.city !== undefined && showDNSResult.org !== undefined;

  useEffect(() => {
    if (openESView)
      setVulnerabilitstText(intl.formatMessage({ id: "hideDetails" }));
    else
      setVulnerabilitstText(intl.formatMessage({ id: "moreDetails" }));
  }, [openESView]);
  return (
    <View style={styles.leftContainer} >
      <Text style={styles.title} type={TextType.Headline1}>{intl.formatMessage({ id: "homeNetworkSecurity" })}</Text>
      <View style={styles.leftContainerPadding}>
        <Text style={styles.subTitle} type={TextType.SubtitleBold}>{intl.formatMessage({ id: "quicklyAndEasily" })}</Text>
        <View style={{ marginTop: 16 }}>
          <Text style={styles.subTitle} type={TextType.Subtitle}>{intl.formatMessage({ id: "ourInnovativeTool" })}</Text>
        </View>
      </View>
      {scanStatus === scanStatuses.PROGRESS_BAR_COMPLETED && showDNSResult &&
        <>
          {showLocationAndProvider &&
            <DnsDetails provider={provider} locationText={locationText} />
          }
          {riskLevel !== null &&
            <RiskLevelDetails
              openESView={openESView}
              riskLevelColor={riskLevelColor}
              safetyLevel={safetyLevel}
              riskLevelText={riskLevelText}
              handleOpenESView={handleOpenESView}
              vulnerabilitstText={vulnerabilitstText}
            />}
        </>
      }
    </View>
  )
}

const useStyle = (theme, openESView = false) => {
  const isSmallDevice = isSmallScreen();

  const styles = StyleSheet.create({
    leftContainer: {
      flexDirection: 'column',
      flex: !isSmallDevice && 1.5,
      minHeight: isSmallDevice ? 320 : openESView ? 316 : 416,
      flexGrow: isSmallDevice ? 0 : 1.5,
      backgroundColor: theme.colors.background.white,
      paddingTop: isSmallDevice ? 24 : 60,
      paddingBottom: isSmallDevice ? 24 : 0,
      paddingLeft: isSmallDevice ? 24 : 60,
      paddingRight: isSmallDevice ? 24 : 60,
      borderBottomLeftRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 0 : 8,
      borderTopLeftRadius: isSmallDevice ? 0 : theme.isRTL ? 0 : 8,
      borderBottomRightRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 8 : 0,
      borderTopRightRadius: isSmallDevice ? 0 : theme.isRTL ? 8 : 0,
    },
    leftContainerPadding: {
      paddingBottom: 24,
      paddingTop: 16,
    },
    title: {
      textAlign: theme.isRTL ? 'right' : 'left',
      writingDirection: theme.isRTL ? 'rtl' : 'ltr',
      color: theme.colors.text.link
    },
    subTitle: {
      textAlign: theme.isRTL ? 'right' : 'left',
      writingDirection: theme.isRTL ? 'rtl' : 'ltr',
      color: theme.colors.text.subtitle
    },
  });
  return { styles };
}
export default LeftContainer;