import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme, Text } from "@suranceadmin/surance-ui-components";
import { isSmallScreen } from '../utils';

export const RenderItemSmall = ({ item, index }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme);


    return (
        <View key={item + index} style={{ flex: 1, flexGrow: 1, flexDirection: 'column' }}>

            {Object.entries(item).map(([key, value], index) => {
                const title = key.charAt(0).toUpperCase() + key.slice(1);
                return (

                    <View style={{ marginTop: 8 }} key={index}>
                        {<Text style={styles.title}>{title}</Text>}
                        <Text style={[styles.data, {
                            color: '#000000'
                        }]}>{value}</Text>
                    </View>
                )
            }
            )}
        </View>
    )
}

export const RenderItem = ({ item, index }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme);
    const { width } = getBorderWidth(index);
    const title = item.title.charAt(0).toUpperCase() + item.title.slice(1);

    return (
        <View key={item + index} style={{ flex: index === 3 ? 3.5 : 0.4, flexDirection: 'column' }}>
            {<Text numberOfLines={1} style={styles.title}>{title}</Text>}
            <View style={{
                borderTopWidth: 1,
                borderColor: 'rgb(192, 192, 192)',
                width: width,
            }} />

            {item.data.map((x, index) => {
                return (
                    <View
                        style={{ minHeight: 200, marginTop: index === 0 ? 8 : 20 }} key={index}>
                        <Text
                            style={[styles.data, {
                                color: '#000000'
                            }]}>{x}</Text>
                    </View>
                )
            }
            )}
        </View >
    )
}

function getBorderWidth(index) {
    const width = {
        [0]: {
            width: '70%'
        },
        [1]: {
            width: '70%'
        },
        [2]: {
            width: '70%'
        },
        [3]: {
            width: '100%'
        },
    };
    return width[index] || { width: '20%' };
}


const useStyle = (theme) => {
    const isSmallDevice = isSmallScreen();

    const styles = StyleSheet.create({
        data: {
            fontFamily: 'Inter-Regular',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: 12,
            lineHeight: 16,
        },
        title: {
            fontFamily: 'Inter-Bold',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: 12,
            lineHeight: 24,
            marginBottom: 5,
        }

    });

    return { styles };
}