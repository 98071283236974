import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/he";
import { I18nManager } from "react-native";
import Constants from "expo-constants";
import React, { useContext, createContext, useEffect, useState } from "react";
import { useIntl as useReactIntl, IntlProvider } from "react-intl";
import { getParsedTranslations, getMissingKeys } from "./utils";
import { languages } from "../../constants";

// VS: Imports with * created an object with default alias
// and resulted in extra keys being added to parsed translations
const en = require("./translations/en");
const he = require("./translations/he");

const translations = getParsedTranslations({ en, he });

export const checkRtlState = (locale) => {
  const isRtl = locale === languages.HEBREW;
  if (isRtl !== I18nManager.isRTL) {
    I18nManager.allowRTL(isRtl);
    I18nManager.forceRTL(isRtl);
  }
};

const IntlProviderRtl = ({ locale, ...props }) => {
  if (!translations[locale]) {
    locale = languages.ENGLISH;
  }
  const [transl, setTransl] = useState(translations[locale]);

  useEffect(() => {
    checkRtlState(locale);
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={transl}>
      {props.children}
    </IntlProvider>
  );
};

const KeyContextContext = createContext();

export const useIntl = () => {
  const context = useContext(KeyContextContext);
  const intl = useReactIntl();
  if (context === undefined) {
    throw new Error("useIntl must be used within UITextKeyProvider");
  }

  return { ...context, ...intl };
};

if (__DEV__) {
  const missingKeys = getMissingKeys(translations);
  missingKeys.forEach((x) => console.error(x));
}

export default {
  IntlProvider: IntlProviderRtl,
  translations,
  currentLocale: Constants.manifest.locale || navigator.language || languages.ENGLISH,
};
