import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useIntl } from 'react-intl';
import { useTheme, Text, TextType } from '@suranceadmin/surance-ui-components';
import { isSmallScreen } from '../../../utils';

export const NoNetwork = () => {
    const { styles } = useStyle();
    const intl = useIntl();
    return (
        <View style={{ alignItems: 'center' }}>
            <Text style={styles.noNetwork} type={TextType.BodyBold}>{intl.formatMessage({ id: "noNetwork" })}</Text>
            <Text style={styles.textBelowIcon} type={TextType.Caption2}>
                {intl.formatMessage({ id: "noNetworkMessage" })}
            </Text>
        </View>
    )
}

const useStyle = () => {
    const isSmallDevice = isSmallScreen();
    const theme = useTheme();

    const styles = StyleSheet.create({
        textBelowIcon: {
            paddingTop: 16,
            textAlign: 'center',
        },
        noNetwork: {
            textAlign: 'center',
            paddingTop: isSmallDevice ? 40 : 60,
            color: theme.colors.text.warning,
        }
    });
    return { styles };
}

export default NoNetwork;