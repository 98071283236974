import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie'
import LeftContainer from './components//LeftContainer';
import RightContainer from './components/RightContainer';
import { StyleSheet, View } from 'react-native';
import { isSmallScreen, isExtraBigScreen } from '../utils';
import { safetyLevels, scanStatuses } from '../../constants';
import { activateLasers } from './ActiveLasers';
import { v4 as uuidv4 } from 'uuid';

const Scan = ({ handleOpenESView, handleShowESresult, openESView }) => {
    const [showDNSResult, setShowDNSResult] = useState({});
    const [cookies, setCookie] = useCookies(['scanned'])
    const [riskLevel, setRiskLevel] = useState(null);
    const [safetyLevel, setSafetyLevel] = useState(safetyLevels.UNKNOWN);
    const [scanStatus, setScanStatus] = useState(scanStatuses.SCAN_NOT_STARTED);
    const [scanProgressPercentage, setScanProgressPercentage] = useState(0);
    const [noNetwork, setNoNetwork] = useState(false);
    const { styles } = useStyle(openESView);
    const [uniqueId, setUniqueId] = useState('');

    useEffect(() => {
        setUniqueId(uuidv4());
    }, []);

    window.addEventListener('offline', () => {
        setNoNetwork(true)
    });

    window.addEventListener('online', () => {
        setNoNetwork(false)
        setScanProgressPercentage(0);
        if (![scanStatuses.SCAN_NOT_STARTED, scanStatuses.SCAN_COMPLETED].includes(scanStatus)) {
            setScanStatus(scanStatuses.SCAN_FAILED)
        }
    });

    const startScans = async () => {
        setScanStatus(scanStatuses.SCAN_STARTED)
        try {
            await activateLasers({ uniqueId, setScanProgressPercentage, setScanStatus, setCookie, setShowDNSResult, handleShowESresult, setRiskLevel, setSafetyLevel })
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    return (
        <View style={styles.scanContainer}>
            <LeftContainer
                openESView={openESView}
                handleOpenESView={handleOpenESView}
                scanStatus={scanStatus}
                showDNSResult={showDNSResult}
                safetyLevel={safetyLevel}
                riskLevel={riskLevel} />
            <RightContainer
                setScanProgressPercentage={setScanProgressPercentage}
                scanProgressPercentage={scanProgressPercentage}
                openESView={openESView}
                safetyLevel={safetyLevel}
                scanStatus={scanStatus}
                setScanStatus={setScanStatus}
                startScans={startScans}
                cookies={cookies}
                uniqueId={uniqueId}
                noNetwork={noNetwork}
            />
        </View>
    );
}


const useStyle = (openESView) => {
    const isSmallDevice = isSmallScreen();
    const isExtraBigDevice = isExtraBigScreen();


    const styles = StyleSheet.create({
        scanContainer: {
            alignItems: 'stretch',
            flex: 1,
            flexDirection: isSmallDevice ? 'column' : 'row',
            marginLeft: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            marginRight: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            borderRadius: 8,
            backgroundColor: '#FFFFFF',
            marginBottom: isSmallDevice ? 0 : openESView ? 0 : 64
        },
        scanContainerSmall: {
            alignItems: 'stretch',
            flex: 1,
            display: 'block',
            flexDirection: 'column',
            marginLeft: 0,
            marginRight: 0
        },
        boxView: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: 0,
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
        }
    });
    return { styles };
}

export default Scan;