import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useIntl } from 'react-intl';
import { useTheme, Text, TextType } from '@suranceadmin/surance-ui-components';
import { isSmallScreen } from '../../../utils';
import { safetyLevels, analyticsEvents, scanStatuses } from '../../../../constants';
import { useAnalyticsEventTracker } from '../../../../Analtyics';
import { NoNetwork } from './NoNetwork';
import { Intro } from './Intro';
import { ScanFailed } from './ScanFailed';
import { ScanResults } from './ScanResults';
import IMAGES from '../../../images';

const ScanComponentsByStatus = ({ noNetwork, setScanStatus, scanProgressPercentage, scanStatus, onClick, isAlreadyScanned, safetyLevel, hideButton, uniqueId }) => {
    const IntroStatus = <Intro scanStatus={scanStatus} setScanStatus={setScanStatus} scanProgressPercentage={scanProgressPercentage} hideButton={hideButton} isAlreadyScanned={isAlreadyScanned} onClick={onClick} />;
    const NoNetworkStatus = <NoNetwork />;
    const scanStatusMapping = {
        [scanStatuses.SCAN_FAILED]: <ScanFailed onClick={onClick} isAlreadyScanned={isAlreadyScanned} />,
        [scanStatuses.SCAN_NOT_STARTED]: IntroStatus,
        [scanStatuses.SCAN_STARTED]: IntroStatus,
        [scanStatuses.SCAN_COMPLETED]: IntroStatus,
        [scanStatuses.PROGRESS_BAR_COMPLETED]: <ScanResults safetyLevel={safetyLevel} uniqueId={uniqueId} />,
    }

    return noNetwork ? NoNetworkStatus : scanStatusMapping[scanStatus];
}

const RightContainer = ({ setScanProgressPercentage, noNetwork, scanProgressPercentage, scanStatus, setScanStatus, openESView, safetyLevel, startScans, cookies, uniqueId }) => {
    const { styles } = useStyle(openESView);
    const [hideButton, setHideButton] = useState(false);
    const [isAlreadyScanned, setIsAlreadyScanned] = useState(false);

    const onClick = (label) => {
        if (scanStatus === scanStatuses.SCAN_FAILED) {
            setScanStatus(scanStatuses.SCAN_NOT_STARTED)
            setScanProgressPercentage(0);
        }
        if (cookies.scanned && [scanStatuses.SCAN_COMPLETED, scanStatuses.SCAN_NOT_STARTED].includes(scanStatus)) {
            setIsAlreadyScanned(true);
        }
        else {
            useAnalyticsEventTracker(analyticsEvents.BUTTON_CLICKED, analyticsEvents.SCAN_STARTED, label);
            setHideButton(true);
            setScanStatus(scanStatuses.SCAN_STARTED);
            startScans();
        }
    }

    return (
        <View style={styles.rightContainer}>
            <ScanComponentsByStatus noNetwork={noNetwork} setScanStatus={setScanStatus} scanProgressPercentage={scanProgressPercentage} scanStatus={scanStatus} onClick={onClick} isAlreadyScanned={isAlreadyScanned} safetyLevel={safetyLevel} hideButton={hideButton} uniqueId={uniqueId} />
        </View>
    )
}

export const RiskLevelIcon = ({ riskLevel }) => {
    const { styles } = useStyle();
    let riskLevelIcon;
    switch (riskLevel) {
        case safetyLevels.FULLY_SAFE:
            riskLevelIcon = <Image source={IMAGES.fullySafeImg} style={styles.image} />;
            break;
        case safetyLevels.NOT_SAFE:
            riskLevelIcon = <Image source={IMAGES.notSafeImg} style={styles.image} />;
            break
        case safetyLevels.MILDLY_SAFE:
            riskLevelIcon = <Image source={IMAGES.mildelySafeImg} style={styles.image} />;
            break;
        default:
            riskLevelIcon = <Image source={IMAGES.unknownImg} style={styles.image} />;
    }
    return (
        <View style={styles.riskImgContainer}>
            {riskLevelIcon}
        </View>
    )
}

export const RiskLevelBodyText = ({ isAlreadyScanned }) => {
    const { styles } = useStyle();
    const intl = useIntl();

    return (
        <View>
            {isAlreadyScanned ?
                <Text testID="already scanned" style={styles.textBelowIcon} type={TextType.Caption1}>
                    {intl.formatMessage({ id: "youHaveAlreadyScanned" })}
                </Text> :
                <Text style={styles.textBelowIcon} type={TextType.Caption1}>
                    {intl.formatMessage({ id: "conntectedToWifi" })}
                </Text>
            }
        </View>
    )
}

const useStyle = (openESView = false) => {
    const isSmallDevice = isSmallScreen();
    const theme = useTheme();

    const styles = StyleSheet.create({
        rightContainer: {
            alignItems: 'center',
            backgroundColor: theme.colors.background.default,
            alignSelf: 'stretch',
            flexGrow: 1,
            gap: 16,
            minHeight: isSmallDevice ? 350 : openESView ? 388 : 488,
            flex: 1,
            paddingLeft: isSmallDevice ? 40 : 60,
            paddingRight: isSmallDevice ? 40 : 60,
            borderBottomRightRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 0 : 8,
            borderTopRightRadius: isSmallDevice ? 0 : theme.isRTL ? 0 : 8,
            borderBottomLeftRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 8 : 0,
            borderTopLeftRadius: isSmallDevice ? 0 : theme.isRTL ? 8 : 0,
        },
        riskImgContainer: {
            alignSelf: 'center',
            paddingTop: isSmallDevice ? 40 : 60
        },
        image: {
            width: 120,
            height: 144,
            alignSelf: 'center'
        },
        textBelowIcon: {
            paddingTop: 16,
            textAlign: 'center',
        },
    });

    return { styles };
}

export default RightContainer;