const en = {
  scanId: "ScanID: {shortScanId}",
  beingScanned: "Your router and WiFi are being scanned, please wait...",
  homeNetworkSecurity: "Home WiFi Security Check-Up",
  ourInnovativeTool: "Our innovative tool will scan your home router and Wi-Fi network for any unauthorized modifications or security vulnerabilities. We will also identify any devices on your network that are accessible to hackers.",
  quicklyAndEasily: "Quickly and easily assess the security of your home network",
  conntectedToWifi: "It is important to ensure that you are connected to Wi-Fi before running the scan.",
  startTesting: "Start testing",
  congratulations: "Congratulations!",
  homeNetworkSafeAtTheMoment: "Your home network appears to be safe at the moment. We recommend rescanning every once in a while to identify vulnerabilities.",
  mediumSecurityRisk: "There is a problem with your home network that could pose a medium security risk. Please contact our customer support via <b>support@surance.io</b> and copy the scan ID for further assistance.",
  routerSecurityAlert: "Router Security Alert",
  noRisksFound: "No risks found",
  highRisksFound: "High risks found",
  lowRisksFound: "Low risks found",
  highSecurityRisk: "There is a problem with your home network that could pose a high security risk. Please contact our customer support via <b>support@surance.io</b> and copy the scan ID for further assistance.",
  reachcustomerSupport: "To learn how to address this issue, please reach out to customer support for further assistance.",
  contactUs: "Contact us",
  allRightsRegistered: "© Surance.io. All rights registered.",
  provider: "Provider:",
  location: "Location:",
  youHaveAlreadyScanned: "You have already scanned your network.",
  noNetwork: "No Network",
  noNetworkMessage: "Please Check your internet connection and try again, if problem persists contact support.",
  tryAgain: "Try Again",
  homeNetworkVulnerabilities: "Home network vulnerabilities",
  scanFailed: "Unfortunately, we could not scan your home network",
  rescan: "Rescan Now",
  riskLevel: "Risk Level",
  safetyLevel: "Safety Level",
  high: "High",
  medium: "Medium",
  low: "Low",
  none: "Safe",
  includesDataFrom: "Includes data from",
  moreDetails: "More Details",
  hideDetails: "Hide Details",
}
module.exports = en;
