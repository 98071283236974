import { instance } from "./http_client";

export async function startScan({ uid }) {
    const res = await instance.post('/api/publicScan', {
        uid: uid,
    });
    return res;
}

export async function getResult({ uid }) {
    const { data } = await instance.get(`/api/result?uid=${uid}`)
    return data;
}