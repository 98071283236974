import React from 'react';
import { StyleSheet, View, Image, } from 'react-native';
import { useTheme, Text, TextType } from "@suranceadmin/surance-ui-components";
import { isSmallScreen } from '../../../utils';
import { safetyLevels } from '../../../../constants';
import IMAGES from '../../../images';


export const RiskLevelDetails = ({ openESView, riskLevelColor, safetyLevel, riskLevelText, handleOpenESView, vulnerabilitstText }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme, openESView);

    return (
        <View style={styles.location} >
            <Image style={[styles.locationImg, { tintColor: riskLevelColor }]} source={IMAGES.riskLevelImg} />
            <Text style={[styles.resultsText, { color: riskLevelColor }]} type={TextType.Caption1}>{riskLevelText}</Text>
            {safetyLevel !== safetyLevels.FULLY_SAFE && <View style={{ marginStart: 5, marginEnd: 5, borderBottomColor: '#01B0C1', borderBottomWidth: 2, borderStyle: 'dotted' }}>
                <Text onPress={() => handleOpenESView()} style={[styles.resultsText, { color: '#01B0C1' }]} type={TextType.Caption1}>{vulnerabilitstText}</Text>
            </View>
            }
        </View>
    )
}

const useStyle = (theme, openESView = false) => {
    const isSmallDevice = isSmallScreen();

    const styles = StyleSheet.create({
        leftContainer: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'left',
            flex: !isSmallDevice && 1.5,
            minHeight: isSmallDevice ? 320 : openESView ? 316 : 416,
            flexGrow: isSmallDevice ? 0 : 1.5,
            backgroundColor: theme.colors.background.white,
            paddingTop: isSmallDevice ? 24 : 60,
            paddingBottom: isSmallDevice ? 24 : 0,
            paddingLeft: isSmallDevice ? 24 : 60,
            paddingRight: isSmallDevice ? 24 : 60,
            borderBottomLeftRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 0 : 8,
            borderTopLeftRadius: isSmallDevice ? 0 : theme.isRTL ? 0 : 8,
            borderBottomRightRadius: isSmallDevice ? 0 : openESView ? 0 : theme.isRTL ? 8 : 0,
            borderTopRightRadius: isSmallDevice ? 0 : theme.isRTL ? 8 : 0,
        },
        leftContainerPadding: {
            paddingBottom: 24,
            paddingTop: 16,
        },
        leftContainerBorderResult: {
            borderTopWidth: 1,
            borderColor: 'rgb(192, 192, 192)',
            marginTop: -6,
            width: '100%',
        },
        network: {
            flexDirection: 'row',
            display: 'flex',
            paddingTop: 12,
            width: '100%',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        providerContainer: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        location: {
            flexDirection: 'row',
            display: 'flex',
            paddingBottom: 5,
            width: '100%',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        title: {
            textAlign: theme.isRTL ? 'right' : 'left',
            color: theme.colors.text.link
        },
        subTitle: {
            textAlign: theme.isRTL ? 'right' : 'left',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
            color: theme.colors.text.subtitle
        },
        networkImg: {
            width: 20,
            height: 17
        },
        locationImg: {
            width: 20,
            height: 17
        },
        resultsText: {
            textAlign: 'center',
        }

    });
    return { styles };
}

export default RiskLevelDetails;