import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { useIntl } from 'react-intl';
import { Button, ButtonType, useTheme, Text, TextType } from '@suranceadmin/surance-ui-components';
import { isSmallScreen } from '../../../utils';
import { RiskLevelBodyText } from '../RightContainer';
import IMAGES from '../../../images';



export const ScanFailed = ({ onClick, isAlreadyScanned }) => {
    const { styles } = useStyle();
    const intl = useIntl();
    return (
        <View style={styles.scanFailed}>
            <ScanFailedIcon />
            <Text style={[styles.mildlySafe, { color: '#CB3E5A' }]} type={TextType.BodyBold}>
                {intl.formatMessage({ id: "scanFailed" })}
            </Text>
            <Button
                type={ButtonType.Primary}
                style={styles.button}
                onPress={() => onClick('rescan')} >
                {intl.formatMessage({ id: "rescan" })}
            </Button>
            <RiskLevelBodyText isAlreadyScanned={isAlreadyScanned} />
        </View>
    )
}

const ScanFailedIcon = () => {
    const { styles } = useStyle();
    return (
        <View style={styles.riskImgContainer}>
            <Image source={IMAGES.scanFailedImg} style={[styles.image, {
                width: 144,
                height: 144
            }]} />
        </View>
    )
}

const useStyle = () => {
    const isSmallDevice = isSmallScreen();
    const theme = useTheme();

    const styles = StyleSheet.create({
        mildlySafe: {
            textAlign: 'center',
            color: theme.colors.level.mildlySafe,
            paddingTop: 16,
        },
        riskImgContainer: {
            alignSelf: 'center',
            paddingTop: isSmallDevice ? 40 : 60
        },
        image: {
            width: 120,
            height: 144,
            alignSelf: 'center'
        },
        button: {
            width: '100%',
            maxWidth: 270,
            height: 48,
            marginTop: 16,
            marginHorizontal: isSmallDevice && 12.5
        },
        textBelowIcon: {
            paddingTop: 16,
            textAlign: 'center',
        },
        scanFailed: {
            alignItems: 'center',
            paddingBottom: isSmallDevice ? 40 : 0
        },
    });

    return { styles };
}

export default ScanFailed;