
import ReactGA from "react-ga";
import { isProduction } from "./src/utils";


export const useAnalyticsEventTracker = (category: string, action: string, label: string) => {
    const production = isProduction();
    if (production) {
        ReactGA.event({ category, action, label });
    }
}