import { useState, useEffect } from "react";
import { scanStatuses } from "../../../../constants";

const PERCENTAGE_FINAL_VALUE = 100;
const AUTO_COMPLETE_STEP_PERCENTAGE = 100;

const ChangingProgressProvider = ({ scanStatus, setScanStatus, scanProgressPercentage, children }) => {
    const [value, setValue] = useState(0);
    const [autoComplete, setAutoComplete] = useState(false);
    const [spinnerFinishedLoading, setSpinnerFinishedLoading] = useState(false);

    useEffect(() => {
        if (autoComplete && !spinnerFinishedLoading) {
            let intervalId;
            if (value === PERCENTAGE_FINAL_VALUE) {
                setSpinnerFinishedLoading(true);
                setScanStatus(scanStatuses.PROGRESS_BAR_COMPLETED)
                setAutoComplete(false);
            } else {
                intervalId = setInterval(() => {
                    setValue((value + 1));
                }, AUTO_COMPLETE_STEP_PERCENTAGE);
            }
            return () => clearInterval(intervalId);
        }
    }, [autoComplete, value]);

    useEffect(() => {
        const checkScanStatus = async () => {
            switch (scanProgressPercentage) {
                case 0:
                    setTimeout(() => setValue(5), 2000);
                    break;
                case 100:
                    if (value === 95) {
                        setValue(scanProgressPercentage);
                    } else {
                        setAutoComplete(true);
                    }
                    break;
                default:
                    setValue(scanProgressPercentage);
                    break;
            }
        };
        checkScanStatus();
    }, [scanProgressPercentage]);


    useEffect(() => {
        if (scanStatus === scanStatuses.SCAN_COMPLETED && !autoComplete) {
            setScanStatus(scanStatuses.PROGRESS_BAR_COMPLETED);

        }
    }, [scanStatus, value]);

    return children(value);
}
export default ChangingProgressProvider;
