import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useTheme, Text, TextType } from "@suranceadmin/surance-ui-components";
import { useIntl } from 'react-intl';
import { isSmallScreen, isChrome, isExtraBigScreen } from '../utils';
import IMAGES from '../images';
import { languages } from '../../constants';

const languagesInfo = {
  [languages.ENGLISH]: {
    name: "English",
    code: languages.ENGLISH,
  },
  [languages.HEBREW]: {
    name: "עברית",
    code: languages.HEBREW,
  },
};

const TopBar = ({ changeLanguage }) => {

  const intl = useIntl();
  const theme = useTheme();
  const { styles } = useStyle();
  const locale = intl.locale;
  const inactiveLanguage = Object.values(languagesInfo).filter((x) => x.code !== locale)
  const { name, code } = inactiveLanguage[0];

  const onContactUs = () => {
    const win: Window = window;
    win.location = 'mailto:support@surance.io';
  }

  return (
    <View style={styles.container}>
      <Image style={styles.suranceLogo} source={IMAGES.logo} />
      <Image style={styles.suranceText} source={IMAGES.logoWordImg} />
      <TouchableOpacity onPress={() => changeLanguage(code)}>
        <Text testID="language button" style={[styles.languageText, { color: theme.colors.text.link }]} type={TextType.Caption2}>
          {name}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onContactUs}>
        <Image style={styles.contactUsLogo} source={IMAGES.letterImg} />
        <View style={styles.contactUsTextContainer}>
          <Text testID="contactUs button" style={{ color: theme.colors.text.link }} type={TextType.Caption2}> {intl.formatMessage({ id: "contactUs" })}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const useStyle = () => {
  const isSmallDevice = isSmallScreen();
  const isExtraBigDevice = isExtraBigScreen();

  const styles = StyleSheet.create({
    container: {
      marginRight: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
      marginLeft: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
      minHeight: isSmallDevice ? 88 : 118,
      position: 'relative',

    },
    suranceLogo: {
      width: isSmallDevice ? 34 : 46,
      height: isSmallDevice ? 39 : 53,
      position: 'absolute',
      top: isSmallDevice ? 25 : 32,
      left: isSmallDevice ? 24 : 0,
    },
    suranceText: {
      position: 'absolute',
      top: isSmallDevice ? 32 : 43,
      left: isSmallDevice ? 65 : 55,
      width: isSmallDevice ? 118 : 160,
      height: isSmallDevice ? 24 : 32,
    },
    languageText: {
      position: 'absolute',
      top: isSmallDevice ? 32 : 48,
      right: isSmallDevice ? 78 : 125,
      margin: 0,
    },
    contactUsLogo: {
      position: 'absolute',
      top: isSmallDevice ? 29 : 44,
      right: isSmallDevice ? 24 : isChrome ? 75 : 85,
      PointerEvent: 'auto',
      cursor: 'pointer',
      width: 30,
      height: 30,
    },
    contactUsTextContainer: {
      position: 'absolute',
      display: isSmallDevice && 'none',
      top: 48,
      right: 0,
    },
  });
  return { styles };
}

export default TopBar;