import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useIntl } from 'react-intl';
import { useTheme, Text, TextType } from '@suranceadmin/surance-ui-components';
import { isSmallScreen } from '../../../utils';
import { safetyLevels, } from '../../../../constants';
import * as Clipboard from 'expo-clipboard';
import { RiskLevelIcon } from '../RightContainer';
import IMAGES from '../../../images';

const RouterSafetyStatus = ({ safetyLevel }) => {
    const routerStatusMapping = {
        [safetyLevels.FULLY_SAFE]: <RouterIsSafe />,
        [safetyLevels.NOT_SAFE]: <RouterIsNotSafe />,
        [safetyLevels.MILDLY_SAFE]: <RouterIsMildlySafe />
    };

    return routerStatusMapping[safetyLevel];
}

export const ScanResults = ({ safetyLevel, uniqueId }) => {
    const { styles } = useStyle();
    const intl = useIntl();
    const shortScanId = uniqueId.slice(-12);
    const copyScanId = async () => {
        await Clipboard.setStringAsync(shortScanId);
        alert('Copied to Clipboard!');
    }

    return (
        <View style={styles.container}>
            <RouterSafetyStatus safetyLevel={safetyLevel} />
            <View style={styles.scanId}>
                <Text type={TextType.Caption1}>
                    {intl.formatMessage({ id: "scanId" }, { shortScanId })}
                </Text>
                <TouchableOpacity onPress={copyScanId}>
                    <Image source={IMAGES.copyImg} style={{
                        width: 20,
                        height: 20,
                        marginStart: 5,
                        marginEnd: 5,
                    }} />
                </TouchableOpacity>
            </View>
        </View>
    )
}

function RouterIsSafe() {
    const { styles } = useStyle();
    const intl = useIntl();

    return (
        <View>
            <RiskLevelIcon riskLevel={safetyLevels.FULLY_SAFE} />
            <Text style={styles.safe} type={TextType.BodyBold}>{intl.formatMessage({ id: "noRisksFound" })}</Text>
            <Text style={styles.textBelowIcon} type={TextType.Caption1}>{intl.formatMessage({ id: "homeNetworkSafeAtTheMoment" })}</Text>
        </View>
    )
}

function RouterIsNotSafe() {
    const { styles } = useStyle();
    const intl = useIntl();

    return (
        <View>
            <RiskLevelIcon riskLevel={safetyLevels.NOT_SAFE} />
            <Text style={styles.notSafe} type={TextType.BodyBold}>{intl.formatMessage({ id: "highRisksFound" })}</Text>
            <Text style={styles.textBelowIcon} type={TextType.Caption1}>{intl.formatMessage({ id: "highSecurityRisk" }, {
                b: (chunks) => (
                    <Text
                        type={TextType.Caption1Bold}>
                        {chunks}
                    </Text>
                ),
            },)}</Text>
        </View>
    )
}


function RouterIsMildlySafe() {
    const { styles } = useStyle();
    const intl = useIntl();

    return (
        <View>
            <RiskLevelIcon riskLevel={safetyLevels.MILDLY_SAFE} />
            <Text style={styles.mildlySafe} type={TextType.BodyBold}>{intl.formatMessage({ id: "lowRisksFound" })}</Text>
            <Text style={styles.textBelowIcon} type={TextType.Caption1}>{intl.formatMessage({ id: "mediumSecurityRisk" }
                , {
                    b: (chunks) => (
                        <Text
                            type={TextType.Caption1Bold}>
                            {chunks}
                        </Text>
                    ),
                },)}</Text>
        </View>
    )

}

const useStyle = () => {
    const isSmallDevice = isSmallScreen();
    const theme = useTheme();

    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            paddingBottom: 16
        },
        notSafe: {
            textAlign: 'center',
            color: theme.colors.text.warning,
            paddingTop: 16,
        },
        mildlySafe: {
            textAlign: 'center',
            color: theme.colors.level.mildlySafe,
            paddingTop: 16,
        },
        safe: {
            textAlign: 'center',
            color: theme.colors.level.fullySafe,
            paddingTop: 16
        },
        image: {
            width: 120,
            height: 144,
            alignSelf: 'center'
        },
        button: {
            width: '100%',
            maxWidth: 270,
            height: 48,
            marginTop: 16,
            marginHorizontal: isSmallDevice && 12.5
        },
        textBelowIcon: {
            paddingTop: 16,
            textAlign: 'center',
        },
        scanId: {
            flexDirection: 'row',
            marginTop: 20
        }
    });

    return { styles };
}

export default ScanResults;