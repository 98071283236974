export const safetyLevels = {
  FULLY_SAFE: 'fullySafe',
  NOT_SAFE: 'notSafe',
  MILDLY_SAFE: 'mildlySafe',
  UNKNOWN: 'unknown',
};

export const riskLevels = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
  NONE: 'none',
};

export const analyticsEvents = {
  SCAN: 'scan',
  SCAN_STARTED: 'scanStarted',
  SCAN_COMPLETED: 'scanCompleted',
  SCAN_FAILED: 'scanFailed',
  BUTTON_CLICKED: 'Button Clicked',
  SCAN_BUTTON_CLICKED: 'scanButtonClicked'
};

export const scanStatuses = {
  SCAN_STARTED: 'scanStarted',
  SCAN_COMPLETED: 'scanCompleted',
  PROGRESS_BAR_COMPLETED: 'progressBarCompleted',
  SCAN_FAILED: 'scanFailed',
  SCAN_NOT_STARTED: 'scanNotStarted',
};

export const scanTypes = {
  DNS: 'dns',
  ES: 'es',
};

export const languages = {
  ENGLISH: 'en',
  HEBREW: 'he',
};

export const REJECTED = 'rejected';

export const MAX_REQUESTS = 10;
export const SERVICES_NUMBER = 2;
export const SERVICE_REQUEST_PERCENTAGE = MAX_REQUESTS / SERVICES_NUMBER;

export const BASE_URL = 'https://es.prd.surance-app.io/';

export const LITE_IP_SERVICE_URL = 'https://lite.ip2location.com';
