import ScanContainer from './scan';
import React, { useState, useEffect } from 'react';
import Loading from './components/Loading';
import TopBar from './components/TopBar';
import BottomBar from './components/BottomBar';
import Locale from "./locale";
const { IntlProvider, currentLocale } = Locale;
import { StyleSheet, View, I18nManager, ImageBackground } from 'react-native';
import { ThemeProvider as SuranceThemeProvider } from "@suranceadmin/surance-ui-components";
import { initOrUpdateTheme } from './themes/light.theme';
import { isSmallScreen, setTabNameAndIcon, isProduction } from './utils';
import ReactGA from 'react-ga';
import { useFonts } from 'expo-font';
import IMAGES from './images';
import { languages} from '../constants'; 

const TRACKING_ID = "UA-262375455-1";


function App() {
  const [locale, setLocale] = useState(currentLocale);
  const [theme, setTheme] = useState(initOrUpdateTheme);
  const isSmallDevice = isSmallScreen();
  const [fontsLoaded] = useFonts({
    'OpenSansHebrew-Regular': require('../assets/fonts/OpenSansHebrew-Regular.ttf'),
    'OpenSansHebrew-Bold': require('../assets/fonts/OpenSansHebrew-Bold.ttf'),
    'Montserrat-Regular': require('../assets/fonts/Montserrat-Regular.ttf'),
    'Montserrat-Bold': require('../assets/fonts/Montserrat-Bold.ttf'),
    'Inter-Regular': require('../assets/fonts/Inter-Regular.ttf'),
    'PTSans-Regular': require('../assets/fonts/PTSans-Regular.ttf'),
    'Inter-Bold': require('../assets/fonts/Inter-Bold.ttf'),
  });
  const production = isProduction();

  useEffect(() => {
    setTabNameAndIcon();
  }, []);

  let isRTL = locale === languages.HEBREW;
  I18nManager.allowRTL(isRTL);
  I18nManager.forceRTL(isRTL);

  const changeLanguage = (lang) => {
    setLocale(lang);
    setTheme(initOrUpdateTheme(lang));
    isRTL = lang === languages.HEBREW;
    if (isRTL !== I18nManager.isRTL) {
      I18nManager.allowRTL(isRTL);
      I18nManager.forceRTL(isRTL);
    }
  }
  if (production) {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview('Security Checkup page is opened');
  }

  if (!fontsLoaded) {
    return <Loading />
  }
  else {
    return (

      <IntlProvider key={locale} locale={locale}>
        <SuranceThemeProvider theme={{ ...theme, ...{ isRTL } }}>
          <View style={styles.appHeader}>
            <ImageBackground source={IMAGES.backgroundImg} resizeMode="repeat" style={styles.image}>
              <TopBar changeLanguage={changeLanguage} />
              <ScanContainer />
              {!isSmallDevice && <View style={styles.borderLine} />}

              <BottomBar />
            </ImageBackground>
          </View>
        </SuranceThemeProvider>
      </IntlProvider>
    )
  }
}
const styles = StyleSheet.create({
  appHeader: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'black',
    backgroundColor: 'black',
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  borderLine: {
    borderTopWidth: 1,
    borderColor: '#01B0C1',
    borderTopStyle: 'solid',
  }
});

export default App;
