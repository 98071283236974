import React from 'react';
import { useIntl } from 'react-intl';
import { View, Image, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import { useTheme, Text, TextType } from "@suranceadmin/surance-ui-components";
import { isSmallScreen, isExtraBigScreen, isMiddleScreen } from '../utils';
import { LITE_IP_SERVICE_URL } from '../../constants';
import IMAGES from '../images';
const BottomBar = () => {
    const intl = useIntl();
    const theme = useTheme();
    const { styles } = useStyle(theme);

    const openIPSite = () => {
        Linking.openURL(LITE_IP_SERVICE_URL);
    }
    return (
        <View style={styles.suranceTextBottomContainer}>
            <Image style={styles.suranceBottomLogo} source={IMAGES.logo} />
            <View style={styles.ip2locationContainer}>
                <Text style={{ color: theme.colors.text.ondark, opacity: 0.5 }} type={TextType.Caption3}>{intl.formatMessage({ id: "includesDataFrom" })}</Text>
                <TouchableOpacity onPress={openIPSite}>
                    <Text type={TextType.Caption3} style={styles.ip2locationLink} >IP2Location LITE</Text>

                </TouchableOpacity>
            </View>
            <View style={styles.suranceByContainer}>
                <Text style={{ color: theme.colors.text.ondark, opacity: 0.5 }} type={TextType.Caption3} >{intl.formatMessage({ id: "allRightsRegistered" })}</Text>
            </View>
        </View>
    )
}

const useStyle = (theme) => {
    const isSmallDevice = isSmallScreen();
    const isExtraBigDevice = isExtraBigScreen();
    const isMiddleDevice = isMiddleScreen();

    const styles = StyleSheet.create({
        suranceTextBottomContainer: {
            display: 'flex',
            marginRight: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            marginLeft: isSmallDevice ? 0 : isExtraBigDevice ? 300 : 150,
            minHeight: 64,
            position: 'relative',
        },
        suranceBottomLogo: {
            alignSelf: 'flex-start',
            position: 'absolute',
            bottom: 16,
            left: isSmallDevice ? 24 : 0,
            width: 27.57,
            height: 31.65,
        },
        suranceByContainer: {
            zIndex: 2,
            position: 'absolute',
            left: isSmallDevice ? null : isMiddleDevice ? null : 0,
            right: isSmallDevice ? 24 : isMiddleDevice ? 24 : 0,
            bottom: isSmallDevice ? 32 : isMiddleDevice ? 32 : 24,
            justifyContent: 'center',
            alignItems: 'center'
        },
        ip2locationContainer: {
            zIndex: 3,
            position: 'absolute',
            right: isSmallDevice ? 24 : isMiddleDevice ? 24 : 0,
            bottom: isSmallDevice ? 16 : isMiddleDevice ? 16 : 24,
            flexDirection: 'row',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        ip2locationLink: {
            paddingStart: 5,
            paddingEnd: 5,
            color: theme.colors.text.link,
            opacity: 0.5
        }
    })
    return { styles };
}
export default BottomBar;