
import React from 'react';
import { StyleSheet, View, Image, } from 'react-native';
import { useTheme, Text, TextType } from "@suranceadmin/surance-ui-components";
import { isSmallScreen } from '../../../utils';
import IMAGES from '../../../images';

export const DnsDetails = ({ provider, locationText }) => {
    const theme = useTheme();
    const { styles } = useStyle(theme);

    return (
        <>
            <View style={styles.leftContainerBorderResult} />
            <View style={styles.network} >
                <Image style={styles.networkImg} source={IMAGES.networkImg} />
                <View style={styles.providerContainer} >
                    <Text testID="provider" numberOfLines={1} ellipsizeMode='tail' style={styles.resultsText} type={TextType.Caption1}>{provider}</Text>
                </View>
            </View>
            <View style={styles.location} >
                <Image style={styles.locationImg} source={IMAGES.locationImg} />
                <Text testID="location" numberOfLines={1} ellipsizeMode='tail' style={styles.resultsText} type={TextType.Caption1}>{locationText}</Text>
            </View>
        </>
    )
}

const useStyle = (theme) => {

    const styles = StyleSheet.create({
        leftContainerBorderResult: {
            borderTopWidth: 1,
            borderColor: 'rgb(192, 192, 192)',
            marginTop: -6,
            width: '100%',
        },
        network: {
            flexDirection: 'row',
            display: 'flex',
            paddingTop: 12,
            width: '100%',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        providerContainer: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        location: {
            flexDirection: 'row',
            display: 'flex',
            paddingBottom: 5,
            width: '100%',
            writingDirection: theme.isRTL ? 'rtl' : 'ltr',
        },
        networkImg: {
            width: 20,
            height: 17
        },
        locationImg: {
            width: 20,
            height: 17
        },
        resultsText: {
            textAlign: 'center',
        },
    });
    return { styles };
}

export default DnsDetails;