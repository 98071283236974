import React from "react";
import { View } from "react-native";

export const ItemDivider = () => {
    return (
        <View
            style={{
                height: 1,
                width: "100%",
                backgroundColor: 'rgb(192, 192, 192)',
                marginTop: 20,
                marginBottom: 8
            }}
        />
    );
}