import { languages } from "../../constants";
import Locale from "./../locale";
const { currentLocale } = Locale;

const Colors = {
  background: {
    white: "#FFFFFF",
    default: "#F0F0F0",
    header: "#012230",
    headerModal: "#052E40",
    important: "#F9EEE2",
    warning: "#CB3E5A",
  },
  button: {
    primary: "#01B0C1",
    secondary: "#FFFFFF",
    negative: "#CB3E5A",
    disabled: "#DDE2E8",
    secondaryBorder: "#01B0C1",
    transparentBorder: "#FFFFFF",
  },
  text: {
    default: "#052E40",
    light: "#546B84",
    ondark: "#FFFFFF",
    disabled: "#93A3B6",
    link: "#01B0C1",
    warning: "#CB3E5A",
    buttonSecondary: "#052E40",
    subtitle: "001E2B",
  },
  line: {
    default: "#93A3B6",
    light: "#DDE2E8",
  },
  level: {
    unknown: "#93A3B6",
    notSafe: "#CB3E5A",
    mildlySafe: "#DD8206",
    fullySafe: "#3E78C7",
  },
  notification: {
    critical: "#CB3E5A",
    important: "#DD8206",
    improvement: "#E6AF0B",
  },
  icon: {
    default: "#052E40",
    link: "#01B0C1",
    ondark: "#FFFFFF",
    warning: "#CB3E5A",
    critical: "#CB3E5A",
    important: "#DB7B2B",
    improvement: "#E6AF0B",
    valid: "#01B0C1",
  },
};

const FontsEnglish = {
  headline1: {
    fontFamily: 'Montserrat-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 40,
  },
  headline2: {
    fontFamily: "BebasNeue-Regular",
    fontSize: 32,
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: "Montserrat-Regular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 24,
  },
  subtitleBold: {
    fontFamily: 'Montserrat-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  body: {
    fontFamily: "PTSans-Regular",
    fontSize: 18,
    lineHeight: 24,
  },
  bodyBold: {
    fontFamily: 'Montserrat-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 24,
  },
  bodyWide: {
    fontFamily: "PTSans-Regular",
    fontSize: 18,
    lineHeight: 28,
  },
  buttonPrimary: {
    fontFamily: "Montserrat-Bold",
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 1,
    marginBottom: -3.5,
    marginTop: -0.5,
  },
  buttonSecondary: {
    fontFamily: "Montserrat-Bold",
    fontSize: 16,
    lineHeight: 16,
  },
  overline: {
    fontFamily: "PTSans-Regular",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  overlineBold: {
    fontFamily: "PTSans-Bold",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  caption1: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 20,
  },
  caption1Bold: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 12,
    lineHeight: 20,
  },
  caption2: {
    fontFamily: 'PTSans-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 21,
  },
  caption2Bold: {
    fontFamily: "PTSans-Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  caption3: {
    fontFamily: 'PTSans-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 16,
  },
  caption3Bold: {
    fontFamily: "PTSans-Bold",
    fontSize: 12,
    lineHeight: 16,
  },
};

const FontsHebrew = {
  headline1: {
    fontFamily: 'OpenSansHebrew-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 32,
    lineHeight: 40,
  },
  headline2: {
    fontFamily: "OpenSansHebrew-Regular",
    fontSize: 32,
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: "OpenSansHebrew-Regular",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 24,
  },
  subtitleBold: {
    fontFamily: 'OpenSansHebrew-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  body: {
    fontFamily: "OpenSansHebrew-Regular",
    fontSize: 18,
    lineHeight: 24,
  },
  bodyBold: {
    fontFamily: 'OpenSansHebrew-Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 24,
  },
  bodyWide: {
    fontFamily: "OpenSansHebrew-Regular",
    fontSize: 18,
    lineHeight: 28,
  },
  buttonPrimary: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 1,
    marginBottom: -3.5,
    marginTop: -0.5,
  },
  buttonSecondary: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 16,
    lineHeight: 16,
  },
  overline: {
    fontFamily: "OpenSansHebrew-Regular",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  overlineBold: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  caption1: {
    fontFamily: 'OpenSansHebrew-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 20,
  },
  caption1Bold: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 12,
    lineHeight: 20,
  },
  caption2: {
    fontFamily: 'OpenSansHebrew-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 21,
  },
  caption2Bold: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  caption3: {
    fontFamily: 'OpenSansHebrew-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 16,
  },
  caption3Bold: {
    fontFamily: "OpenSansHebrew-Bold",
    fontSize: 12,
    lineHeight: 16,
  },
};

export const initOrUpdateTheme = (lang = null) => {
  const fontsByCurrentLocale = currentLocale === languages.HEBREW ? FontsHebrew : FontsEnglish;
  const fontsByLang = lang === languages.HEBREW ? FontsHebrew : FontsEnglish;
  const fonts = lang ? fontsByLang : fontsByCurrentLocale;
  const Theme = {
    colors: Colors,
    fonts: fonts,
  };
  return Theme;
}
