import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useIntl } from 'react-intl';
import { Button, ButtonType, Text, TextType, useTheme } from '@suranceadmin/surance-ui-components';
import { isSmallScreen } from '../../../utils';
import { safetyLevels } from '../../../../constants';
import ChangingProgressProvider from "./ChangingProgressProvider";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { RiskLevelIcon, RiskLevelBodyText } from '../RightContainer';

export const Intro = ({ scanStatus, setScanStatus, scanProgressPercentage, hideButton, isAlreadyScanned, onClick }) => {
    return (
        <View >
            {hideButton ?
                <ShowScanning scanStatus={scanStatus} setScanStatus={setScanStatus} scanProgressPercentage={scanProgressPercentage} />
                :
                <BeforeScanning
                    onClick={onClick}
                    isAlreadyScanned={isAlreadyScanned}
                />
            }
        </View>
    )
};

const ShowScanning = ({ scanStatus, setScanStatus, scanProgressPercentage }) => {
    const { styles } = useStyle();
    const intl = useIntl();
    return (

        <View style={styles.scanProgressContainer}>
            <RadarSpinner scanStatus={scanStatus} setScanStatus={setScanStatus} scanProgressPercentage={scanProgressPercentage} />
            <Text testID="scan in progress" style={styles.textBelowIcon} type={TextType.Caption1}>{intl.formatMessage({ id: "beingScanned" })}</Text>
        </View>
    )
}


const RadarSpinner = ({ scanStatus, setScanStatus, scanProgressPercentage }) => {
    const { styles } = useStyle();
    const theme = useTheme();
    return (
        <View style={styles.radarContainer}>
            <ChangingProgressProvider scanStatus={scanStatus} setScanStatus={setScanStatus} scanProgressPercentage={scanProgressPercentage}  >
                {percentage => (
                    <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                            pathTransitionDuration: 0.15,
                            pathColor: theme.colors.text.link,
                            textColor: theme.colors.text.link,
                        })}
                    />
                )}
            </ChangingProgressProvider>
        </View>
    )
};

const BeforeScanning = ({ onClick, isAlreadyScanned }) => {
    const { styles } = useStyle();
    const intl = useIntl();

    return (
        <View style={{ alignItems: 'center' }}>
            <RiskLevelIcon riskLevel={safetyLevels.UNKNOWN} />
            <Button
                testID="start scan button"
                type={ButtonType.Primary}
                style={styles.button}
                onPress={() => onClick('first scan')} >
                {intl.formatMessage({ id: "startTesting" })}
            </Button>
            <RiskLevelBodyText isAlreadyScanned={isAlreadyScanned} />
        </View>
    )
}


const useStyle = () => {
    const isSmallDevice = isSmallScreen();

    const styles = StyleSheet.create({
        scanProgressContainer: {
            alignItems: 'center',
            paddingBottom: 16
        },
        radarContainer: {
            paddingTop: isSmallDevice ? 40 : 108,
            width: 200,
            height: 300,
        },
        button: {
            width: '100%',
            maxWidth: 270,
            height: 48,
            marginTop: 16,
            marginHorizontal: isSmallDevice && 12.5
        },
        textBelowIcon: {
            paddingTop: 16,
            textAlign: 'center',
        },
    });

    return { styles };
}


export default Intro;