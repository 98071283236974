import axios from 'axios';
import { instance } from './http_client';

export const getResult = async (uid) => {
    const response = await instance.get('/api/dns/result', {
        params: {
            uid: uid
        }
    })
    return response;
}
export const initDnsInfo = async (uid) => {
    await instance.post('/api/dns/init', {
        uid: uid
    })
}

export const getMyIp = async () => {
    const { data } = await axios.get("https://api.ipify.org?format=json")
    return data
}


