const IMAGES = {
    copyImg: require('../public/copy.png'),
    scanFailedImg: require('../public/ScanFailed.svg'),
    fullySafeImg: require('../public/fullySafe.svg'),
    notSafeImg: require('../public/notSafe.svg'),
    mildelySafeImg: require('../public/mildelySafe.svg'),
    unknownImg: require('../public/unknown.svg'),
    networkImg: require('../public/Network.svg'),
    locationImg: require('../public/Location.svg'),
    riskLevelImg: require('../public/RiskLevel.svg'),
    logo: require('../public/Logo.svg'),
    logoWordImg: require('../public/logoWord.svg'),
    letterImg: require('../public/Letter.svg'),
    backgroundImg: require('../public/Background.png'),
}

export default IMAGES;