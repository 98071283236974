import React, { useCallback, useEffect, useState } from "react";
import Scan from "./Scan";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@suranceadmin/surance-ui-components";
import ESContainer from "./ESContainer";

const ScanContainer = () => {
  const theme = useTheme();
  const [showESresult, setShowESresult] = useState({});
  const [openESView, setOpenESView] = useState(false);
  const [layout, setLayout] = useState(0);

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setLayout(height);
  }

  const handleShowESresult = useCallback((esresult) => {
    setShowESresult(esresult);
  }, []);

  const handleOpenESView = () => {
    setOpenESView(!openESView);
  };

  useEffect(() => {
    if (openESView === true) {
      window.scrollTo({
        top: layout,
        behavior: 'smooth',
      });
    }
  }, [openESView]);

  return (
    <View style={{ flex: 1, flexDirection: 'column' }} onLayout={onLayout}>
      <View style={[styles.container, { writingDirection: theme.isRTL ? 'rtl' : 'ltr' }]}>
        <Scan handleOpenESView={handleOpenESView} handleShowESresult={handleShowESresult} openESView={openESView} />
      </View>
      {Object.keys(showESresult).length !== 0 && openESView === true && <ESContainer showESresult={showESresult} />}
    </View>

  );
};

export default ScanContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  }
});


