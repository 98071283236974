import * as dns from "../scanner/dns";
import * as es from "../scanner/es";
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { analyticsEvents, scanStatuses, MAX_REQUESTS, scanTypes, SERVICE_REQUEST_PERCENTAGE, REJECTED } from '../../constants';
import { useAnalyticsEventTracker } from '../../Analtyics';
import { safefyLevelCalculator } from '../utils';
import { timer } from "../locale/utils";

const maxPercentage = MAX_REQUESTS * SERVICE_REQUEST_PERCENTAGE;

export const activateLasers = async ({ uniqueId, setScanProgressPercentage, setScanStatus, setCookie, setShowDNSResult, handleShowESresult, setRiskLevel, setSafetyLevel }) => {
    dns.initDnsInfo(uniqueId)
    await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds
    try {
        await es.startScan({ uid: uniqueId });

        try {
            // this will always show an error - do not remove try/catch
            await axios.get(`https://${uniqueId}.dnstest.surancecheck.me`);
        } catch (error) {
            Sentry.captureException(error);
        }

        const [dnsInfo, esInfo] = await Promise.allSettled([resultLoop({ setScanProgressPercentage, uniqueId, scanType: 'dns' }), resultLoop({ setScanProgressPercentage, uniqueId, scanType: 'es' })]);

        let resultFound = null;
        let esRiskLevel = null;
        const isScanFailed = dnsInfo.status === REJECTED && esInfo.status === REJECTED;

        if (isScanFailed) {
            setScanStatus(scanStatuses.SCAN_FAILED)
        } else {
            let expires = new Date()
            expires.setTime(expires.getTime() + (600000))   // 10 minutes 
            setCookie('scanned', true, { path: '/', expires })
            setScanStatus(scanStatuses.SCAN_COMPLETED)
        }

        const analyticsScanStatus = isScanFailed ? analyticsEvents.SCAN_FAILED : analyticsEvents.SCAN_SUCCESS;
        useAnalyticsEventTracker(analyticsEvents.SCAN, analyticsScanStatus, analyticsScanStatus)
        if (dnsInfo.status !== REJECTED) {
            setShowDNSResult(dnsInfo.value);
            resultFound = dnsInfo.value.result.found;
        }
        if (esInfo.status !== REJECTED) {
            handleShowESresult(esInfo.value);
            esRiskLevel = esInfo.value.risk;
            setRiskLevel(esRiskLevel);
        }

        const level = safefyLevelCalculator({ resultFound, esRiskLevel });
        setSafetyLevel(level);
    } catch (error) {
        Sentry.captureException(error);
    }
}

const getDnsResult = async (i, uniqueId, setScanProgressPercentage) => {
    const result = await dns.getResult(uniqueId);
    const done = result.data.done;
    if (!done) {
        return null;
    }
    const percentageResult = maxPercentage - i * SERVICE_REQUEST_PERCENTAGE;
    setScanProgressPercentage(prevScanProgressPercentage => prevScanProgressPercentage + percentageResult);
    const dnsInfo = result.data.dnsList[0];
    return dnsInfo;
}

const getEsResult = async (i, uniqueId, setScanProgressPercentage) => {
    const result = await es.getResult({ uid: uniqueId });
    const done = result.ok === true;
    if (!done) {
        return null;
    }
    const percentageResult = maxPercentage - i * SERVICE_REQUEST_PERCENTAGE;
    setScanProgressPercentage(prevScanProgressPercentage => prevScanProgressPercentage + percentageResult);
    const esInfo = result.result;
    return esInfo;
}


const getResultByScanType = async (i, uniqueId, scanType, setScanProgressPercentage) => {
    const scanTypeMapping = {
        [scanTypes.DNS]: getDnsResult,
        [scanTypes.ES]: getEsResult,
    }
    const result = await scanTypeMapping[scanType](i, uniqueId, setScanProgressPercentage);
    return result;
}


async function resultLoop({ setScanProgressPercentage, uniqueId, scanType = scanTypes.DNS }) {
    let index = 0;
    try {
        let requestResult;
        for (let i = 1; i <= MAX_REQUESTS; i++) {
            index = i;
            setScanProgressPercentage(prevScanProgressPercentage => prevScanProgressPercentage + 5);
            requestResult = await getResultByScanType(i, uniqueId, scanType, setScanProgressPercentage);
            if (requestResult) {
                break;
            }
            await timer(3000)
        }
        if (!requestResult) {
            throw new Error('Scan failed');
        }
        return requestResult;
    } catch (error) {
        const percentageResult = maxPercentage - index * SERVICE_REQUEST_PERCENTAGE;
        setScanProgressPercentage(prevScanProgressPercentage => prevScanProgressPercentage + percentageResult);
        throw Error(error);

    }
}

export default activateLasers;